<template>
  <div class="device">
    <div class="header">
      <div class="primary">
        <div class="title">智慧科技，引领未来</div>
        <div class="subtitle">智能化管理的物联网解决方案</div>
      </div>
    </div>
    <div class="content" v-if="screenWidth > 768">
      <div :id="item.id" class="item" v-for="(item, index) in data" :key="index">
          <img :src="item.img">
          <div class="secondary">
            <div class="title">{{item.title}}</div>
            <div class="subtitle">{{item.subtitle}}</div>
            <div class="text">{{item.text1}}</div>
            <div class="text">{{item.text2}}</div>
          </div>
        </div>
    </div>
    <div class="content" v-else>
      <div :id="item.id" class="index" v-for="(item, index) in data" :key="index">
        <div class="secondary">
          <div class="img">
            <img :src="item.img">
            <div class="wrap">
              <div class="title">{{item.title}}</div>
              <div class="subtitle">{{item.subtitle}}</div>
            </div>
          </div>
          <div class="text">{{item.text1}}</div>
        </div>
        <div class="text">{{item.text2}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'device',
  data() {
    return {
      data: [
        {
          id: 'deviceLock',
          img: require('../assets/image/device/lock1.png'),
          title: 'NB-IOT智能门锁',
          subtitle: 'Smart Door Lock',
          text1: '多种验证方式，"包括密码、指纹、人脸、卡片、身份证、手机等多种开锁方式。通过手机远程开锁、监控门锁状态。可以临时授权他人或访客进入。',
          text2: '先进的加密技术和多种身份验证方式，提升门禁安全性。无需携带钥匙，远程控制和临时授权功能提高了出入门的便利性。与智能家居系统联动，提供智能化的生活体验。'
        },
        {
          id: 'deviceMeter',
          img: require('../assets/image/device/meter1.png'),
          title: '智能水电表',
          subtitle: 'Water And Electricity Meter',
          text1: '实时监测，实时监测水、电使用情况，及用量消耗情况等。远程读数和控制功能，手机APP实现远程管理。记录水电使用数据，并生成报表，帮助用户了解用量情况和节能情况。',
          text2: '智能水电表可实时监测用量，节能环保，能有效提升管理效率，帮助用户合理使用资源，实现智能化、高效化管理。'
        },
        {
          id: 'deviceAccess',
          img: require('../assets/image/device/access1.png'),
          title: '智能门禁',
          subtitle: 'Intelligent Access Control',
          text1: '记录管理，记录出入门的时间、身份信息等，便于管理者了解门禁使用情况。对未授权尝试进入或异常情况进行报警提醒，提高安全性。',
          text2: '严格的身份验证和监控功能，防止未经授权的人员进入，保障居民的生命和财产安全；有效提高小区管理的效率，以及进行安全管理；为居民提供了更加智能、安全、便捷的居住环境。'
        },
        {
          id: 'deviceGate',
          img: require('../assets/image/device/gate1.png'),
          title: '车辆/人行道闸',
          subtitle: 'Vehicle/pedestrian Gate',
          text1: '通过识别系统验证身份，有效控制车辆或行人出入。监测车辆或行人进出情况，便于安全管理和应对突发事件。根据实时情况调整道闸开启与关闭状态，有效管理交通流量和人员流动。',
          text2: '智慧道闸等设施，能提供安全管理和实时监控以及精准管控人员流动，减少安全风险，能有效提升通行效率，为场所管理带来便利和安全保障。'
        },
        {
          id: 'deviceAlarm',
          img: require('../assets/image/device/alarm1.png'),
          title: '烟雾/燃气报警器',
          subtitle: 'Smoke/gas Alarm',
          text1: '监测烟雾或燃气浓度，预警火灾风险，自动发出声光信号。高效的检测技术和自动报警功能，有助于节约能源，减少安全事故带来的资源浪费。',
          text2: '烟雾、燃气报警器可以及时预警火灾或燃气泄漏，最大程度的保障人身和财产安全，还能为外出人员提供远程监控，实现智能安全防护。'
        }
      ],
      screenWidth: document.body.clientWidth
    }
  },
  methods: {
    getWidth() {
      const that = this
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth
          that.screenWidth = window.screenWidth
        })()
      }
    }
  },
  created() {
    this.getWidth()
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        to.name && this.$nextTick(() => {
          const element = document.querySelector(`#${to.name}`),
            top = this.screenWidth > 768 ? 102 : 60
          element && window.scrollTo(0, element.offsetTop - top)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.device {
  .header {
    height: 400px;
    background: url(../assets/image/device/bg1.png) no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    .primary {
      width: 1200px;
      margin: 0 auto;
      color: #ffffff;
      .title {
        font-size: 44px;
      }
      .subtitle {
        margin-top: 2px;
        font-size: 20px;
      }
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    .item {
      display: flex;
      &:nth-child(odd) {
        img {
          margin-right: 40px;
        }
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        img {
          margin-left: 40px;
        }
      }
      img {
        height: 300px;
      }
      .secondary {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 24px;
          color: #030d1e;
        }
        .subtitle {
          font-size: 16px;
          color: #607499;
        }
        .text {
          margin-top: 30px;
          color: #545558;
        }
      }
    }
    .item + .item {
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: 768px) {
  .device {
    margin-top: 50px;
    .header {
      height: 200px;
      background: url(../assets/image/device/bg2.png) no-repeat center;
      background-size: cover;
      .primary {
        width: 90%;
        .title {
          font-size: 20px;
        }
        .subtitle {
          font-size: 12px;
        }
      }
    }
    .content {
      width: 100%;
      padding: 20px 10px;
      background-color: #fbfdff;
      .index {
        padding: 14px;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(7,37,81,0.07);
        border-radius: 2px;
        &:nth-child(odd) {
          .img {
            margin-right: 20px;
          }
        }
        &:nth-child(even) {
          .secondary {
            flex-direction: row-reverse;
          }
          .img {
            margin-left: 20px;
          }
        }
        .secondary {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .img {
            position: relative;
            img {
              height: 140px;
            }
            .wrap {
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
              background: rgba(12,24,52,0.2);
              backdrop-filter: blur(10px);
              padding: 4px;
              color: #ffffff;
              .title {
                font-size: 12px;
              }
              .subtitle {
                font-size: 11px;
                opacity: 0.6;
              }
            }
          }
        }
        .text {
          font-size: 12px;
          color: #768396;
        }
      }
      .index + .index {
        margin-top: 10px;
      }
    }
  }
}
</style>